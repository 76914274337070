<template>
  <div class="site">
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=G-V98K0NTZT8"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <Transition name="fade">
      <LazyLayoutBG2 v-if="infoStore.hasInteraction" />
    </Transition>

    <LayoutHeader />

    <slot />

    <LayoutFooter class="mt-84 lg:mt-[124px]" />

    <LazyLayoutGHW class="sticky bottom-0 z-1" v-if="$route.meta.ghw" />

    <ClientOnly>
      <Teleport to="body">
        <ModalAgeGate />
        <ModalAlert />
        <NotificationCoinsEarned />
      </Teleport>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { useInfoStore } from '~~/store/info';

const infoStore = useInfoStore();
</script>
